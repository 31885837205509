/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */

/* @import url("assets/css/bootstrap.min.css"); */

/* @import url("../src/assets/css/prism.min.css");
@import url("../src/assets/css/sweetalert2.min.css");
@import url("../src/assets/css/bootstrap-timepicker.min.css");
@import url("../src/assets/css/wizard.css");
@import url("../src/assets/css/leaflet.css");
@import url("../src/assets/css/jquery-jvectormap-2.0.3.css");
@import url("../src/assets/css/ng2-toastr.min.css");
@import url("../src/assets/css/nouislider.min.css");
@import url("../src/assets/css/data-table/index.css");
@import url("../src/assets/css/data-table/icons.css");
@import url("../src/assets/css/demo.css"); */

@import url("../src/assets/sass/scss/vyoo/utilities.scss");
@import url("https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:400,500,600,700");
@font-face {
  font-family: 'Grotesk';
  src: url('/assets/fonts/grotesk/BDOGrotesk-Medium.otf') format('otf'),
  /* font-style: normal; */
}

/* ESTILOS AUTOCOMPLETAR */

.custom-slider .ngx-slider .ngx-slider-selection {
  background: #FA7B46 !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #F85F1F !important;
}

/* Elimino cartel Powered by Google */

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

/* Elimino icono delante de cada opción */

.pac-icon-marker {
  display: none !important;
}

/* Cambio de fuente */

.pac-item {
  font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
}

.pace .pace-activity {
  top: 19px;
  right: 19px;
  display: none;
}

.loading-message {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 29rem;
  right: 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: dimgray;
}

.dots {
  display: inline-block;
}

.dots span {
  animation: blink 1.5s infinite both;
  opacity: 0;
}

.dots span:nth-child(1) {
  animation-delay: 0.3s;
}

.dots span:nth-child(2) {
  animation-delay: 0.6s;
}

.dots span:nth-child(3) {
  animation-delay: 0.9s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.page-loading {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: rgba(51, 51, 51, 1);
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: -1;
}

app-root:empty ~ .page-loading {
  opacity: 1;
  z-index: 1;
}

.dropdown-menu {
  max-width: fit-content;
}

.spinner {
  width: 50px;
  height: 50px;
  position: relative;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #F85F1F;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.input-group {
  margin-bottom: 0 !important;
  border-radius: 6px;
}
/* Mat Autocomplete on Modal Fixes */
.cdk-overlay-container {
  z-index: 999999 !important;
}

.static-header {
  align-self: center;
}

.container-main {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.paraf-header {
  padding-bottom: 15px;
}

.container-text {
  margin-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
  justify-content: center;
}

.string-long {
  white-space: normal;
  word-wrap: break-word;
}

/*Input fecha y hora alineados*/
.inputDateTime {
  margin-bottom: 0;
}
button.btn.btn-link.ng-star-inserted {
  margin-bottom: 0;
}
/*POPUP MAPA*/
#imgPopup {
  width: 150px;
  height: 100px;
  overflow: hidden;
}
.titlePopup {
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #7a7a7a;
}
.gm-style .gm-style-iw-c {
  padding: 0;
  height: 140px;
  width: 450px;
  border-radius: 2rem;
}
.gm-style .gm-style-iw-d {
  overflow: hidden;
}
.noPadding {
  padding: 0 !important;
}
.action-view {
  color: #10B981 !important;
}
.action-edit {
  color: #F59E0B !important;
}
.action-delete {
  color: #ED5A46 !important;
}

.swal2-actions .swal2-cancel {
  -webkit-order: 1;
  order: 1;
}
.swal2-actions .swal2-confirm {
  -webkit-order: 2;
  order: 2;
}

#liConfig {
  position: absolute;
  bottom: 0;
  padding: 0;
}

html,
body {
  height: auto;
}
.ph-row-picture {
  display: flex;
  flex-wrap: nowrap;
}
.ph-vertical-center {
  align-self: center;
}
.ph-col-small {
  flex: 0 0 10%;
}
.ph-gap {
  gap: 1em;
}
.ph-no-margin div {
  margin: 0;
}
.ph-first {
  margin-top: 0.6em !important;
}
.ph-gap-small {
  column-gap: 1em;
  row-gap: 0.5em;
}
.page-loading {
  background-color: rgba(220, 220, 220, 0.1) !important;
  backdrop-filter: blur(1px);
}

.badge {
  color: white;
}

.swal2-container.swal2-center > .swal2-popup {
  border-radius: 1.25rem;
  padding: 3rem;
}

.swal2-html-container {
  font-size: 1rem !important;
}

.swal2-textarea {
  border-radius: 1.25rem !important;
}

.swal2-title {
  padding-top: 0;
  color: #1f1f1f;
  line-height: 120%;
  font-size: 32px;
}

.swal-content {
  text-align: initial;
  padding: 0 9.375rem;
  font-size: smaller;
}

.swal-button {
  border-radius: 40px;
  padding: 10px;
  width: 200px;
  border: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.swal-button-squared {
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  border: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.swal-button-confirm {
  color: white;
  background-color: #69c9c1 !important;
}

.swal-button-dismiss {
  color: #545f5d;
  background-color: #f5f5f5 !important;
}

.swal-button-confirm-green {
  color: white;
  background-color: #37d197 !important;
}

.swal-button-dismiss-red {
  color: white;
  background-color: #f46f69 !important;
}

.swal2-actions {
  display: flex;
  gap: 5rem;
}

.swal2-container {
  z-index: 999999 !important;
}

.carousel-inner > .carousel-item > img {
  min-height: 100%;
}

.carousel-item {
  height: 100% !important;
}

.carousel-inner {
  height: 100%;
}

.carousel {
  height: 100%;
}

.mat-form-field,
.mat-stepper-horizontal {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

form label {
  letter-spacing: normal !important;
}

.empty-results {
  align-self: center;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-results > img {
  padding-bottom: 2rem;
  width: 12rem;
}

.no-results {
  font-weight: 600;
  font-size: x-large;
}


.hotel-form-modal > .mat-dialog-container {
  padding: 0;
  border-radius: 45px;
  overflow: unset;
}
.understand-swal .swal2-html-container {
  margin: 0;
  margin-top: 1em;
}
.understand-swal .swal-title {
  padding: 0.8em 0 0;
}
.search-has-failed-container {
  font-style: italic;
  font-weight: 600;
  text-decoration: underline;
  text-align: right;
  text-decoration-color: #F85F1F;
}
.search-has-failed-container button {
  padding: 1em 2em;
  cursor: pointer;
  background-color: #F85F1F;
}
.createCarSelectContainer .mat-select-trigger {
  height: 100%;
}
