.carousel-inner > .carousel-item > img{
	display: block;
    max-width: 100%;
    height: auto;
    line-height: 1;
    object-fit: cover;
}

.carousel {
    width: 90%;
}