.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 20px 40px -8px #0000000F;
}

.app-sidebar {
  z-index: 100;
}

.main-panel {
  margin-top: 85px;
}

.layout-dark {
  .navbar {
    background-color: $bg-color-dark;
  }
}

.layout-dark.layout-transparent {
  &.bg-glass-1 {
    .navbar {
      background-image: url("../../../img/gallery/bg-glass-1.jpg");
    }
  }

  &.bg-glass-2 {
    .navbar {
      background-image: url("../../../img/gallery/bg-glass-2.jpg");
    }
  }

  &.bg-glass-3 {
    .navbar {
      background-image: url("../../../img/gallery/bg-glass-3.jpg");
    }
  }

  &.bg-glass-4 {
    .navbar {
      background-image: url("../../../img/gallery/bg-glass-4.jpg");
    }
  }

  &.bg-hibiscus {
    .navbar {
      background-image: $bg-hibiscus;
    }
  }

  &.bg-purple-pizzazz {
    .navbar {
      background-image: $bg-purple-pizzazz;
    }
  }

  &.bg-blue-lagoon {
    .navbar {
      background-image: $bg-blue-lagoon;
    }
  }

  &.bg-electric-violet {
    .navbar {
      background-image: $bg-electric-violet;
    }
  }

  &.bg-portage {
    .navbar {
      background-image: $bg-portage;
    }
  }

  &.bg-tundora {
    .navbar {
      background-image: $bg-tundora;
    }
  }
}
