.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  .content-header {
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 1.6rem;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .content-sub-header {
    color: darken($body-bg, 60%);
  }

  &.nav-collapsed {
    .app-sidebar {
      width: 5%;

      @media (width: 1920px) {
        width: 4%;
      }

      .menu-title {
        display: none;
      }

      .badge {
        display: none;
      }

      .text {
        display: none;
      }

      .nav-toggle {
        display: none !important;
      }

      .nav-close {
        display: none !important;
      }

      .logo > a {
        margin-right: 12%;
      }

      a {
        width: 100%;

        img {
          display: flex;
          flex-direction: row;
          margin: 0px auto;
        }
      }
    }

    .nav-container {
      ul {
        li {
          margin-top: 25%;
        }
      }
    }

    .sidebar-header {
      margin-top: 40%;
    }

    .sidebar-content {
      margin-top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    footer {
      padding-left: 5%;
    }
  }

  &.menu-collapsed {
    .app-sidebar {
      .navigation li.has-sub > a:after {
        display: none;
      }
    }
  }
}

.no-display {
  display: none;
}

.sidebar-content p {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}

.bootstrap-navbar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}

.left-icon {
  transform: rotate(180deg);
}

ul {
  height: 100%;
}

.app-sidebar,
.off-canvas-sidebar {
  // font-family: $font-family-base;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 250px;
  box-shadow: 20px 0px 40px -8px #0000000F;

  // @include sidebar-shadow();
  white-space: nowrap;
  visibility: visible;
  -webkit-transition: width 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: width 0.5s cubic-bezier(0, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);

  &.hide-sidebar {
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate3d(-100%, 0, 0);
  }

  .sidebar-content {
    position: relative;
    height: auto;
    overflow: auto;
    z-index: 100;
    margin-top: 25%;

    @media (width: 1920px) {
      margin-top: 25%;
    }
  }

  .logo-img {
    width: 30px;
    display: inline-block;
    max-height: 30px;
    margin-right: 15px;
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav-container {
    ul {
      li {
        @media (width: 1440px) {
          margin-top: 0%;
        }

        margin-top: 7%;
      }
    }
  }

  .navigation {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      display: block;

      &.has-sub {
        > a:after {
          content: "\e929";
          font-family: "feather";
          font-size: 1rem;
          display: inline-block;
          position: absolute;
          right: 15px;
          top: 10px;
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -ms-transform: rotate(0);
          -o-transform: rotate(0);
          transform: rotate(0);
          transition: -webkit-transform 0.2s ease-in-out;
        }

        > a {
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      &.open {
        > a:after {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        > a {
          background: rgba(200, 200, 200, 0.2);
        }
      }

      > a {
        display: flex;
        align-items: center;
        overflow: hidden;
        line-height: $font-size-base * 2;
        padding: 0px 0.5rem;
        margin: 5px 0px 5px 0px !important;
        background-color: transparent;
        color: $black;
        transition: all 300ms linear;

        &:before {
          content: "";
          display: block;
          z-index: -1;
          position: absolute;
          left: -100%;
          top: 0;
          width: 90%;
          height: 100%;

          background: rgba(255, 255, 255, 0.8);
          transition: 0.35s ease left;
          margin-left: 5%;
          border-radius: 10px;
        }
      }

      &:not(.open) {
        > ul {
          display: none;
        }
      }

      &:not(.service-disabled):hover > a {
        // background: rgba(200, 200, 200, 0.2);
        color: $black;
      }

      &.active > a {
        color: $primary !important;

        &:before {
          left: 0;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-size: 1rem;

        li {
          a {
            display: block;
            padding: 8px 18px 8px 60px;
          }

          ul {
            li {
              a {
                padding: 8px 18px 8px 70px;
              }
            }
          }
        }
      }
    }

    .menu-title {
      margin: 0;
      font-weight: 500;
      opacity: 1;
      height: auto;
      -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
      transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
      width: 70%;
      white-space: normal;
      line-height: 1.3rem;
      margin-left: 0.75rem;
    }

    .badge.badge-pill {
      position: absolute;
      right: 28px;
      padding: 4px 8px;
      font-weight: 300;
      -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
      transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    i {
      font-size: 18px;
      float: left;
      margin-right: 10px;
      line-height: 2rem;
      width: 30px;
      text-align: center;
      color: #a9afbb;
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      background: #ffffff;
      opacity: 0.93;
    }
  }

  .logo {
    position: relative;
    padding: 20px 15px;
    z-index: 4;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    a {
      display: flex !important;
      flex-direction: row;
      /* width: 50%; */
      align-items: center;
      justify-content: flex-end;
    }

    .logo-text {
      // text-transform: uppercase;
      padding: 5px 0;
      display: block;
      font-size: 1.6rem;
      letter-spacing: 2px;
      color: $black;
      text-align: left;
      font-weight: 400;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    opacity: 0.33;
  }

  &:after {
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }

  // Background colors
  &[data-background-color="white"] {
    @include set-background-color($white);
  }

  &[data-background-color="black"] {
    @include set-background-color($black);
  }

  &[data-background-color="primary"] {
    @include set-background-color(theme-color("primary"));
  }

  &[data-background-color="info"] {
    @include set-background-color(theme-color("info"));
  }

  &[data-background-color="success"] {
    @include set-background-color(theme-color("success"));
  }

  &[data-background-color="warning"] {
    @include set-background-color(theme-color("warning"));
  }

  &[data-background-color="danger"] {
    @include set-background-color(theme-color("danger"));
  }

  //personalized
  &[data-background-color="bg-sidebar-user"] {
    @include set-gradient-background-color($primary, $primary);
  }

  &[data-background-color="bg-sidebar-employee"] {
    @include set-gradient-background-color(#061311, #003c3b);
  }

  &[data-background-color="bg-sidebar-company"] {
    @include set-gradient-background-color(#393a4f, #55566b);
  }

  &[data-background-color="bg-sidebar-agency"] {
    @include set-gradient-background-color(#ce9a00, #fdeb8e);
  }

  &[data-background-color="bg-sidebar-hub"] {
    @include set-gradient-background-color(#f2e4d8, #f9d2fa);
  }

  // Menu anchor colors
  &[data-background-color="white"] {
    @include set-anchor-color($black);
  }

  &[data-background-color="black"] {
    @include set-anchor-color($white);
  }

  &[data-background-color="red"] {
    @include set-anchor-color($white);
  }

  // Logo Text Color
  &[data-background-color="white"] {
    @include set-logo-text-color($black);
  }

  &[data-color="blue"] {
    @include set-background-color-button(theme-color("info"));
  }

  &[data-color="green"] {
    @include set-background-color-button(theme-color("success"));
  }

  &[data-color="orange"] {
    @include set-background-color-button(theme-color("warning"));
  }

  &[data-color="red"] {
    @include set-background-color-button(theme-color("danger"));
  }

  &[data-color="purple"] {
    @include set-background-color-button(theme-color("primary"));
  }
}

.sidebar-header {
  margin-top: 5%;

  a.nav-toggle {
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    right: 14px;
    top: 32px;
    color: rgba(255, 255, 255, 0.55);
  }

  a.nav-close {
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    right: 14px;
    top: 32px;
    color: white;
  }
}

.off-canvas-sidebar {
  .navigation {
    > li > a,
    > li > a:hover {
      color: $white;
    }

    > li > a:focus {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

.main-panel {
  position: relative;
  z-index: 2;
  float: right;
  // overflow: auto;
  min-height: 100%;
  @include transform-translate-x(0px);
  @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  .main-content {
    position: relative;
    min-height: calc(100% - 140px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  div {
    .footer {
      border-top: 1px solid #e7e7e7;
    }
  }

  > .navbar {
    margin-bottom: 0;
  }
}

.bootstrap-navbar .navigation {
  margin-top: 20px;
  float: none;
}

.main-panel {
  max-height: 100%;
  height: calc(100% - 85px);
  min-height: calc(100% - 85px);
  width: 100%;
}

.sidebar-sm {
  .app-sidebar {
    width: 220px;
  }
}

.sidebar-lg {
  .app-sidebar {
    width: 280px;
  }
}

.wrapper[dir="rtl"] {
  &.nav-collapsed {
    .navbar {
      .navbar-header {
        padding-right: 50px;
        padding-left: auto;
      }
    }

    .main-panel {
      .main-content {
        padding-right: 60px;
        padding-left: auto;
      }
    }

    footer {
      padding-right: 60px;
      padding-left: auto;
    }
  }
}

[dir="rtl"] {
  .content-header {
    text-align: right;
  }

  .content-sub-header {
    text-align: right;
  }

  .wrapper {
    &.nav-collapsed {
      .navbar {
        .navbar-header {
          padding-right: 50px;
          padding-left: auto;
        }
      }

      .main-panel {
        .main-content {
          padding-right: 60px;
          padding-left: auto;
        }
      }

      footer {
        padding-right: 60px;
        padding-left: auto;
      }
    }
  }

  .bootstrap-navbar {
    right: 0;
    left: auto;
  }

  .app-sidebar,
  .off-canvas-sidebar {
    right: 0;
    left: auto;

    //transform: translate3d(0,0,0);
    &.hide-sidebar {
      transform: translate3d(100%, 0, 0);
    }

    .logo-img {
      margin-left: 15px;
      margin-right: auto;
    }

    .logo-tim {
      float: right;
    }

    .navigation {
      padding-right: 0;
      padding-left: auto;

      li {
        &.has-sub {
          > a:after {
            left: 15px;
            right: auto;
          }
        }

        > a {
          padding: 10px 14px 10px 30px;
          margin: 5px 0;
          text-align: right;

          &:before {
            right: -100%;
            left: auto;
            border-left: solid 5px theme-color("danger");
            border-right: 0px;
          }
        }

        &.active > a {
          &:before {
            right: 0;
            left: auto;
          }
        }

        ul {
          li {
            a {
              padding: 8px 60px 8px 18px;
            }

            ul {
              li {
                a {
                  padding: 8px 70px 8px 18px;
                }
              }
            }
          }
        }
      }

      .badge.badge-pill {
        left: 28px;
        right: auto;
      }

      i {
        float: right;
        margin-left: 10px;
        margin-right: auto;
      }
    }

    .sidebar-background {
      right: 0;
      left: auto;
    }

    .logo {
      p {
        float: right;
      }

      .logo-text {
        text-align: right;
      }
    }

    .logo-tim {
      float: right;
    }

    &:after,
    &:before {
      right: 0;
      left: auto;
    }
  }

  .sidebar-header {
    a.nav-toggle {
      left: 14px;
      right: auto;
    }

    a.nav-close {
      left: 14px;
      right: auto;
    }
  }

  .main-panel {
    float: left;
  }
}

// For Menu When Its Collapsed
.nav-collapsed.menu-collapsed {
  ul.navigation {
    .nav-collapsed-open {
      content: "";
      display: block;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-right: solid 5px #ff586b;
      background: rgba(200, 200, 200, 0.2);
      transition: 0.35s ease left;
    }
  }
}

@media (max-width: 960px) {
  .main-panel {
    .main-content {
      min-height: calc(100% - 40px);
    }
  }

  .main-content {
    padding-left: 0 !important;
  }

  footer {
    padding-left: 0 !important;
  }
}

@media(max-width: 620px) {
  .show-auth {
    margin-left: 25px !important;
    display: block !important;
    color: white;
  }

  .sidebar-content{
    margin-top: 0% !important;
  }

  .notification {
    background: $primary-darker;
    border-radius: 1.25rem;
    padding: 0 0.5rem;
    color: $white;
    position: unset;
    text-decoration: none !important;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
}
