.delete-icon {
  &::before {
    content: "\f1f8";
    font-family: "FontAwesome";
  }
}
.upload-icon {
  &::before {
    content: "\f093";
    font-family: "FontAwesome";
  }
}

.download-cloud-icon {
  &::before {
    content: "\f0ed";
    font-family: "FontAwesome";
  }
}

.download-icon {
  &::before {
    content: "\f019";
    font-family: "FontAwesome";
  }
}

.back-cursor {
  width: 2rem;
  height: 2rem;
  &:hover {
    transition: all 0.4s ease-in-out;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.btn-company {
  color: white;
  background-color: #727b84;
  border-color: #727b84;
}

.btn-company:hover:not([disabled]) {
  background-color: #3a3a52;
  border-color: #3a3a52;
}

.buttonDelete {
  color: indianred !important;
  font-weight: 500;
  border: 1px indianred solid;
  position: relative;
}

.buttonDownload {
  color: #205954 !important;
  font-weight: 500;
  border: 1px #205954 solid;
  position: relative;
}

.buttonDelete:hover {
  background: indianred;
  color: $white !important;
}

.labelFile {
  text-transform: none;
  border-radius: 0.25rem;
}
.inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputFile + label {
  cursor: pointer;
  font-size: 0.8em;
  color: white;
  background-color: $primary;
  display: inline-block;
}

.inputFile:focus + label,
.inputFile + label:hover {
  background-color: $dark;
}

.alert-vyoo {
  background-color: #69c9c1;
  border-color: #69c9c1;
  color: white;
  font-weight: 500;
}

.user-background {
  background-image: url("../../../img/svg/login-background.svg");
}

.company-background {
  background-image: url("../../../img/gallery/dark-bg.jpg");
}

.employee-background {
  background-image: url("../../../img/svg/login-background-dark.svg");
}

.btn-back-login-user {
  color: #52c9ad;
  border: 1px solid #52c9ad;
  &:hover {
    color: white;
    background-color: #52c9ad;
    border: 1px solid #52c9ad;
  }
}

.btn-back-login-company {
  color: #55566b;
  border: 1px solid #55566b;

  &:hover {
    color: white;
    background-color: #55566b;
    border: 1px solid #55566b;
  }
}

.btn-back-login-employee {
  color: #17362f;
  border: 1px solid #17362f;

  &:hover {
    color: white;
    background-color: #17362f;
    border: 1px solid #17362f;
  }
}
