@font-face {
  font-family: 'Grotesk';
  src: url('/assets/fonts/grotesk/BDOGrotesk-Medium.otf') format('opentype'),
}
// Theme Colors
$theme-colors: (
  primary: #F85F1F,
  // vyoo-primary: #17362f,
  success: #10B981,
  info: #022C22, // cambiar
  warning: #F59E0B,
  danger: #ED5A46,
  secondary: #868e96, // cambiar
  light: #f8f9fa, // cambiar
  dark: #343a40, // cambiar
);

// Spacing
$spacer: 1.5rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
) !default;

// 2.0
$body-bg: #fff;
$card-bg: #f6f8f8;
$bg-calendar: #fbfbfb;
$overlay: #f7f7f7;
$font-color: #1f1f1f;
$primary: #F85F1F;
$primary-light: #FA7B46;
$primary-lighter: #FEEFE9;
$primary-dark: #C64C19;
$primary-darker: #57210B;
$black: #000;
$dark: #1f1f1f;
$secondary: #F4FDB4;
$secondary-light: #FCFEE8;
$secondary-lighter: #FDFFF4;
$secondary-dark: #DCE4A2; 
$secondary-darker: #55593F; 
$border: #e6e6e6;
$white: white;
$input-disabled: #f4f4f4;
$element-disabled: #a3a3a3;
$element-low: #7A7A7A;
$element-mid: #666;
$success: #10B981;
$danger: #ED5A46;
$error-lighter: #FEF3F2;
$error-light: #F68373;
$error-dark: #DA3D28;
$warning: #F59E0B;
$warning-lighter: #FFFBEB;
$warning-dark: #D97706;
$expired: #022C22;
$lightBlue: #daedfc;
$grey: #e9e9e9;
$ligthGrey: #eeeeee;
$borderGrey: #b2b5b5;
$stepperGrey: #b2b5b5;
$placeholderGrey: #999b9b;
$seeMap-bg: #f5f5f5;
$semi-bold: 600;
$lightGrey: #55566b;

// bg color border
$border-group: rgba(128, 128, 128, 0.22);
// bg modal view
$background-modal-view: rgba(219, 219, 219, 0.22);

.loading-background {
  background-color: rgb(255, 255, 255) !important;
  opacity: 0.6 !important;
  z-index: 1000 !important;
}

// Fonts
$font-family-grotesk: "Grotesk";
$font-family-sans-serif: "Open Sans";
$font-family-content: "Plus Jakarta Sans", sans-serif;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$font-size-root: 15px;
