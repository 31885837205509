@import "scss/variables";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "node_modules/bootstrap/scss/bootstrap";
@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "node_modules/prismjs/themes/prism-okaidia";
@import "node_modules/ngx-toastr/toastr";
@import "scss/app-variables";
@import "scss/mixins";
@import "scss/core/palette";
@import "scss/custom-bootstrap";
@import "scss/custom-bootstrap-rtl";
@import "scss/core";
@import "scss/fonts/weather-icons";
@import "scss/vyoo/utilities.scss";

body {
  background-color: $body-bg;
  color: $font-color;
}

.semi-bold {
  font-weight: 600;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-30 {
  border-radius: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.new-dialog {
  mat-dialog-container {
    border-radius: 25px;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    padding: 0;
  }
}

.fare-list-container {
  .-mat-dialog-container {
    border-radius: 15px;
  }
}

.details-component {
  width: 40vw;
}

.auth-details-component {
  width: 65vw;
  @media (max-width: 1100px) {
    width: 50vw;
  }
  @media (max-width: 900px) {
    width: 60vw;
  }
  @media (max-width: 700px) {
    width: 90vw;
    margin: 0 auto;
  }
}

.search-flight-dialog {
  .cdk-overlay-pane {
    margin-left: 5%;
  }

  .mat-dialog-container {
    padding: 0px !important;
    background: none;
    border-radius: 30px !important;
    overflow: inherit;
  }
}

.cdk-overlay-dark-backdrop,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-color: $font-color;
  opacity: 0.6;
}

.search-card {
  border-radius: 30px;
  background-color: $secondary-light;
}


.button-secondary {
  background-color: $primary;
  border-radius: 20px;
  color: $white;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 3px 6px;
  &:disabled {
    background: $grey;
    cursor: not-allowed;
  }
}

.button-accept {
  padding: 0.5625rem 1.5rem;
  background-color: $primary;
  border-radius: 2rem;
  color: $white;
  font-weight: 500;
  transition: all .2s cubic-bezier(.25,.46,.45,.94);
  &:disabled {
    background: $grey;
    cursor: not-allowed;
  }
}

.button-cancel {
  background-color: $seeMap-bg;
  border-radius: 20px;
  color: $element-disabled;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 3px 6px;
}

// .dropdown-toggle {
//   &[aria-expanded="true"] {
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//   }
// }

.dropdown-menu {
  cursor: pointer;
  min-width: 13rem;
  border: 0;
  margin: 0;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  p {
    color: $font-color;
    margin: 0;
    padding: 1vw 2vw;

    &.selected,
    &:hover {
      background-color: $primary-lighter;
    }

    &.last {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}
form {
  font-family: $font-family-content !important;
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      display: flex;
      flex-wrap: wrap;
      padding: 1em 3em 3em 3.3em;
      gap: 2em 4em;
    }
  }
}
.custom-confirm-dialog {
  mat-dialog-container {
    border-radius: 25px;
    padding: 4vw 10vw;
  }
}
.project-date-field {
  ngb-datepicker {
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 12px;
  }
}
.error{
  border: 1px solid #ED5A46 !important;
}

.custom-modal-size {
  width: 100% !important;
  height: 100% !important;
  @media (min-width: 576px){
  .modal-dialog {
      max-width: 620px !important;
    }
  }
}

.custom-modal-user-card {
  width: 100% !important;
  height: 100% !important;
  @media (min-width: 576px){
  .modal-dialog {
      max-width: 750px !important;
    }
  }
}

.files-dialog {
  .mat-dialog-container {
    overflow: hidden !important;
  }
}