.form-group {
  .checkbox label,
  .radio label {
    color: rgba(#000000, 0.87);
  }
}

// Buttons
.btn-group,
.input-group {
  span {
    &:first-child:not(:last-child) {
      .btn.dropdown-toggle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child:not(:first-child) {
      .btn.dropdown-toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:not(:last-child):not(:first-child) {
      .btn.dropdown-toggle {
        border-radius: 0;
      }
    }
  }
}

.custom-btn {
  padding: 0.5625rem 1.5rem;
  background-color: $primary;
  border-radius: 2rem;
  text-decoration: none;
  color: $white;
  border: none;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s cubic-bezier(.25,.46,.45,.94);
  cursor: pointer;
  &:hover {
    background-color: $black;
    text-decoration: none;
    color: $white;
  }
  &:disabled {
    background: $element-disabled;
    cursor: not-allowed;
  }
  &--close {
    background-color: transparent;
    color: $dark;
    border: 1px solid $border;
    &:hover {
      background-color: $secondary;
      color: $dark;
    }
  }
  &--yellow {
    background-color: $secondary;
    color: $dark;
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
  &--black {
    background-color: $dark;
    color: $white;
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.clear-btn {
  background: $white;
  border: .0625rem solid $border;
  color: $dark;
  padding: 0.5rem;
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  appearance: none;
}

// Fonts
.heading-4 {
  font-size: 2rem;
  line-height: 130%;
  font-weight: 700;
  color: $dark;
}

.heading-5 {
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 700;
  color: $dark;
}

.heading-6 {
  font-size: 1.25rem;
  line-height: 140%;
  font-weight: 700;
  color: $dark;
}

// Icon
.icon-bcg {
  background-color: $secondary;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 2rem;
    height: 2rem;
  }
  &--selected {
    background-color: $primary;
  }
  &--small {
    height: 1.75rem;
    width: 1.75rem;
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  &--large {
    width: 5rem;
    height: 5rem;
    img {
        width: 4rem;
        height: 4rem;
    }
}
}

// List Group
.list-group-item {
  &:hover {
    z-index: 0;
  }
  &.active,
  &.active:focus,
  &.active:hover {
    background-color: theme-color("primary");
    border-color: theme-color("primary");
  }
}

// Labels
.label {
  padding-bottom: 0.1em !important;
  letter-spacing: 0.5px;
  &.label-default {
    background-color: #777 !important;
  }
}

// Media Object
.media-list {
  &.media-bordered {
    .media {
      border-top: 1px solid #e4e7ed;
      padding: 1rem 0;
      &:first-child {
        border-top: 0;
      }
    }
  }
}
.media {
  img,
  iframe {
    margin-right: 1rem !important;
  }
  .media-body + a {
    img {
      margin-right: 0 !important;
      margin-left: 1rem;
    }
  }
  .media-body.text-right + a {
    img {
      margin-left: 0;
    }
  }
  .media-body,
  .media-left,
  .media-right {
    padding-top: 0;
  }
  .round-media {
    border-radius: 50%;
  }
}

// Dropdown
.dropdown-menu {
  display: none;
  opacity: inherit;
  .active {
    a {
      background-color: theme-color("primary");
      &:focus,
      &:hover {
        background-color: theme-color("primary");
      }
    }
  }
  &.show {
    transform: none;
    opacity: 1;
  }
}
.form-control {
  &.open {
    ~ .dropdown-menu {
      transform: none;
      opacity: 1;
    }
  }
}

// Tabs/Navs/Pills
.tab-content {
  padding: 1rem;
}
.nav-tabs {
  .nav-link {
    height: 100%;
    &.active {
      background-color: transparent;
      border-bottom-color: white;
    }
  }
  .nav-item {
    &.show {
      .nav-link {
        background-color: transparent;
        border-bottom-color: white;
      }
    }
  }
}

// Accordion
ngb-accordion {
  [role="tabpanel"] {
    padding: 0 2.5rem !important;
  }
  .card {
    border-bottom: 2px solid #f5f7fa;
    margin: 0;
  }
}

// Sweet Alert
.swal2-modal {
  .swal2-confirm {
    background-color: theme-color("success") !important;
    &:focus {
      box-shadow: none;
    }
  }
  .swal2-cancel {
    background-color: theme-color("danger") !important;
  }
  .swal2-warning {
    color: $warning;
    border-color: $warning;
  }
  .swal2-html-container {
    line-height: 150%;
  }
  .swal2-close {
    color: $dark;
    &:hover {
      color: $primary-dark;
    }
  }
}

[dir="rtl"] {
  .media {
    img,
    iframe {
      margin-left: 1rem !important;
      margin-right: auto !important;
    }
    .media-body + a {
      img {
        margin-left: 0 !important;
        margin-right: 1rem;
      }
    }
    .media-body.text-right + a {
      img {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}

/*CALENDAR*/
ngb-datepicker {
  border-radius: 1.25rem;
  margin-top: 10px;
  border: none;
  z-index: 100000;
}

ngb-datepicker .ngb-dp-day,
ngb-datepicker .ngb-dp-week-number,
ngb-datepicker .ngb-dp-weekday {
  font-family: $font-family-content;
  font-style: normal;
  height: 40px;
  width: 48px;
  z-index: revert-layer !important;
}


ngb-datepicker-navigation-select > .form-select {
  border: none;
  font-family: $font-family-content;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  background: none;
}

ngb-datepicker .ngb-dp-navigation-chevron,
.ngb-dp-arrow.right .ngb-dp-navigation-chevron {
  color: $black;
}
ngb-datepicker .ngb-dp-weekdays div {
  font-weight: 700;
}

[ngbDatepickerDayView] {
  width: 0px;
}
ngb-datepicker ngb-datepicker-month {
  border-radius: 20px;
  background-color: $bg-calendar;
}

ngb-datepicker .ngb-dp-months {
  width: 100%;
}

ngb-datepicker .bg-primary {
  background-color: $primary !important;
}

ngb-datepicker ngb-datepicker-month div {
  border-radius: 1.25rem;
  background-color: $bg-calendar !important;
  color: $black;
}

ngb-datepicker .ngb-dp-header {
  padding: 15px;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

ngb-datepicker .ngb-dp-header,
.ngb-dp-week {
  background-color: $white;
}

ngb-datepicker .custom-day:hover:not(.activeFrom, .range) {
  background-color: #f1f1f1 !important;
  box-shadow: inset 0px 0px 0px 18px #f1f1f1 !important;
}

ngb-datepicker .ngb-dp-month:first-child .ngb-dp-week {
  display: flex;
  justify-content: space-evenly;
  border: 0;
  border-radius: 0 0 15px 15px;
}

ngb-datepicker span.custom-day.ng-star-inserted.faded {
  width: 100% !important;
  height: 100% !important;
}

ngb-datepicker span.custom-day.ng-star-inserted.faded:hover {
  width: 3em !important;
}

ngb-datepicker .btn-light:hover {
  background-color: $primary !important;
  color: white !important;
}

ngb-datepicker .dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

ngb-datepicker .custom-day.focused {
  background: none;
  font-weight: bolder;
  color: #F85F1F;
}


ngb-datepicker .custom-day.activeFrom {
  border-radius: 2em 0em 0em 2em !important;
}

ngb-datepicker .custom-day.activeFrom:hover {
  width: 100% !important;
}


ngb-datepicker .ngb-dp-day.disabled,
.ngb-dp-day.hidden {
  opacity: 0.5;
}

ngb-datepicker.dropdown-menu.show {
  font-size: 0.875em;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);
}

// Hour
ngb-timepicker {
  ngb-tp-spacer {
    width: 3em;
  }

  .ngb-tp{
    display: flex;
    justify-content: center;
  }

  .ngb-tp-hour {
    align-items: center;
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .ngb-tp-minute {
    align-items: center;
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .visually-hidden {
    display: none !important;
  }
}

.hour-departure-modal {
  display: flex;
  flex-direction: column;
  width: 27%;
  justify-content: center;
  top: unset;
  left: 38%;
  align-items: center;
  background-color: white;
  border-radius: 1.125rem;
  padding: 0.5em;
  position: absolute;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
  transform: translateY(55%);
  z-index: 1;
}

.hour-return-modal {
  display: flex;
  flex-direction: column;
  width: 27%;
  justify-content: center;
  top: unset;
  left: 65%;
  align-items: center;
  background-color: white;
  border-radius: 1.125rem;
  padding: 0.5em;
  position: absolute;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 16%);
  z-index: 1;
  transform: translateY(55%);
}

// Slider
.ngx-slider {
  .ngx-slider-pointer {
    background-color: $primary !important;
    width: 0px !important;
    height: 0px !important;
    &.ngx-slider-active {
      z-index: 4;
      &:after {
        background-color: $primary-dark !important;
      }
    }
    &:after {
      background: $primary !important;
      width: 15px !important;
      height: 15px !important;
      border-radius: 10px !important;
      top: 8px !important;
      left: 0px !important;
    }
  }
  .ngx-slider-bar {
    height: 2px;
  }
  .ngx-slider-bubble {
    font-size: 12px !important;
  }
  .ngx-slider-selection {
    background: $primary-light !important;
}
}

.mat-expansion-panel-content, .mat-expansion-panel-header {
  font-family: 'Plus Jakarta Sans';
}

// Radio
.mat-radio-container {
  width: 1.125rem !important;
  height: 1.125rem !important;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0.5; 
  background-color: transparent;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $white; 
  scale: 0.7;
  height: 1.125rem !important;
  width: 1.125rem !important;
}

.mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: $dark; 
  background-color: $dark;
}

.mat-radio-outer-circle {
  background-color: $white;
  border-color: $dark;
  height: 1.125rem !important;
  width: 1.125rem !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $primary;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $primary-light;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #aee6e2;
}

.mat-slide-toggle-bar {
  background-color: #d5d5d5;
}

// Tooltip
.mat-tooltip {
  background-color: $dark; 
  color: $white;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  line-height: 150%;
}

.mat-checkbox {
  font-family: 'Plus Jakarta Sans';
}

.ngx-toastr {
  background-color: #5fb6f8;
}

ngb-datepicker .custom-day.activeFrom,
ngb-datepicker .custom-day.activeTo {
  background-color: #F85F1F !important;
  color: white !important;
  border-radius: 50% !important;
  height: 36px !important;
  width: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  z-index: 2;
  outline: 0;
}

ngb-datepicker .custom-day.range {
  background-color: #F85F1F !important;
  color: white !important;
  font-weight: bold;
  border-radius: 50% !important;
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative;
  z-index: 9999999999 !important
}

ngb-datepicker .custom-day.activeFrom:hover,
ngb-datepicker .custom-day.activeTo:hover {
  background-color: #F85F1F !important;
}

ngb-datepicker .custom-day {
  text-align: center !important;
  padding: 0.2rem 0.25rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-content: center !important;
  height: 100% !important;
  width: 100% !important;
  position: relative;
  z-index: 999999;
}

ngb-datepicker .custom-day.faded {
  background-color: #f1f1f1 !important;
  color: $dark;
  position: relative;
  box-shadow: inset 0px 0px 0px 18px #f1f1f1 !important;
}

ngb-datepicker .custom-day.faded:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: -18px;
  right: -18px;
  height: 100%;
  background-color: #f1f1f1;
  z-index: -99999999999999999 !important;
}

ngb-datepicker .custom-day.faded:first-of-type {
  box-shadow: 14px 0 0 #f1f1f1, -18px 0 0 #f1f1f1 !important;
}

ngb-datepicker .custom-day.faded:last-of-type {
  box-shadow: -14px 0 0 #f1f1f1, 18px 0 0 #f1f1f1 !important;
}

ngb-datepicker {
  overflow: hidden !important;
}

ngb-datepicker .custom-day {
  padding: 0 !important;
  margin: 0 !important;
}

ngb-datepicker .custom-day:hover {
  padding: 0 !important;
  margin: 0 !important;
}