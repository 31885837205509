html {
  width: 100%;
}

body {
  font-family: $font-family-content;
  font-weight: 400;
  line-height: 150%;
  color: $dark;
  min-height: 100vh;
  font-size: 16px;
  &.inverse {
    background: #333333;
    &, .form-control {
      color: $white;
    }
    .modal,
    .panel-default,
    .card {
      &,
      .form-control {
        background-color: initial;
        color: initial;
      }
    }

  }
}

.wrapper{

    &.wrapper-full-page{
        height: auto;
        min-height: 100vh;
    }
}

.content-wrapper{
  padding: 2vw 5vw;
}

.serif-font{
    font-family: $font-family-sans-serif;
}

.page-header {
    height: 60vh;
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

a{
  color: theme-color("primary");
  &:hover,
  &:focus{
      color: darken(theme-color("primary"), 5%);
      text-decoration: none;
  }

  &.text-info{
      &:hover, &:focus{
          color: darken(theme-color("info"), 5%);
      }
  }
}
