.#{$wi-css-prefix}-yahoo-0:before { content: $tornado; }
.#{$wi-css-prefix}-yahoo-1:before { content: $day-storm-showers; }
.#{$wi-css-prefix}-yahoo-2:before { content: $hurricane; }
.#{$wi-css-prefix}-yahoo-3:before { content: $thunderstorm; }
.#{$wi-css-prefix}-yahoo-4:before { content: $thunderstorm; }
.#{$wi-css-prefix}-yahoo-5:before { content: $rain-mix; }
.#{$wi-css-prefix}-yahoo-6:before { content: $rain-mix; }
.#{$wi-css-prefix}-yahoo-7:before { content: $rain-mix; }
.#{$wi-css-prefix}-yahoo-8:before { content: $hail; }
.#{$wi-css-prefix}-yahoo-9:before { content: $showers; }
.#{$wi-css-prefix}-yahoo-10:before { content: $hail; }
.#{$wi-css-prefix}-yahoo-11:before { content: $showers; }
.#{$wi-css-prefix}-yahoo-12:before { content: $showers; }
.#{$wi-css-prefix}-yahoo-13:before { content: $snow; }
.#{$wi-css-prefix}-yahoo-14:before { content: $day-snow; }
.#{$wi-css-prefix}-yahoo-15:before { content: $snow-wind; }
.#{$wi-css-prefix}-yahoo-16:before { content: $snow; }
.#{$wi-css-prefix}-yahoo-17:before { content: $hail; }
.#{$wi-css-prefix}-yahoo-18:before { content: $rain-mix; }
.#{$wi-css-prefix}-yahoo-19:before { content: $dust; }
.#{$wi-css-prefix}-yahoo-20:before { content: $fog; }
.#{$wi-css-prefix}-yahoo-21:before { content: $windy; }
.#{$wi-css-prefix}-yahoo-22:before { content: $smoke; }
.#{$wi-css-prefix}-yahoo-23:before { content: $strong-wind; }
.#{$wi-css-prefix}-yahoo-24:before { content: $strong-wind; }
.#{$wi-css-prefix}-yahoo-25:before { content: $snowflake-cold; }
.#{$wi-css-prefix}-yahoo-26:before { content: $cloudy; }
.#{$wi-css-prefix}-yahoo-27:before { content: $night-cloudy; }
.#{$wi-css-prefix}-yahoo-28:before { content: $day-cloudy; }
.#{$wi-css-prefix}-yahoo-29:before { content: $night-cloudy; }
.#{$wi-css-prefix}-yahoo-30:before { content: $day-cloudy; }
.#{$wi-css-prefix}-yahoo-31:before { content: $night-clear; }
.#{$wi-css-prefix}-yahoo-32:before { content: $day-sunny; }
.#{$wi-css-prefix}-yahoo-33:before { content: $night-partly-cloudy; }
.#{$wi-css-prefix}-yahoo-34:before { content: $day-sunny-overcast; }
.#{$wi-css-prefix}-yahoo-35:before { content: $rain-mix; }
.#{$wi-css-prefix}-yahoo-36:before { content: $hot; }
.#{$wi-css-prefix}-yahoo-37:before { content: $day-storm-showers; }
.#{$wi-css-prefix}-yahoo-38:before { content: $day-storm-showers; }
.#{$wi-css-prefix}-yahoo-39:before { content: $day-storm-showers; }
.#{$wi-css-prefix}-yahoo-40:before { content: $showers; }
.#{$wi-css-prefix}-yahoo-41:before { content: $snow-wind; }
.#{$wi-css-prefix}-yahoo-42:before { content: $snow; }
.#{$wi-css-prefix}-yahoo-43:before { content: $snow-wind; }
.#{$wi-css-prefix}-yahoo-44:before { content: $day-sunny-overcast; }
.#{$wi-css-prefix}-yahoo-45:before { content: $day-storm-showers; }
.#{$wi-css-prefix}-yahoo-46:before { content: $snow; }
.#{$wi-css-prefix}-yahoo-47:before { content: $day-storm-showers; }
.#{$wi-css-prefix}-yahoo-3200:before { content: $stars; }