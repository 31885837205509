.wi-day-sunny:before {content: $day-sunny;}
.wi-day-cloudy:before {content: $day-cloudy;}
.wi-day-cloudy-gusts:before {content: $day-cloudy-gusts;}
.wi-day-cloudy-windy:before {content: $day-cloudy-windy;}
.wi-day-fog:before {content: $day-fog;}
.wi-day-hail:before {content: $day-hail;}
.wi-day-haze:before {content: $day-haze;}
.wi-day-lightning:before {content: $day-lightning;}
.wi-day-rain:before {content: $day-rain;}
.wi-day-rain-mix:before {content: $day-rain-mix;}
.wi-day-rain-wind:before {content: $day-rain-wind;}
.wi-day-showers:before {content: $day-showers;}
.wi-day-sleet:before {content: $day-sleet;}
.wi-day-sleet-storm:before {content: $day-sleet-storm;}
.wi-day-snow:before {content: $day-snow;}
.wi-day-snow-thunderstorm:before {content: $day-snow-thunderstorm;}
.wi-day-snow-wind:before {content: $day-snow-wind;}
.wi-day-sprinkle:before {content: $day-sprinkle;}
.wi-day-storm-showers:before {content: $day-storm-showers;}
.wi-day-sunny-overcast:before {content: $day-sunny-overcast;}
.wi-day-thunderstorm:before {content: $day-thunderstorm;}
.wi-day-windy:before {content: $day-windy;}
.wi-solar-eclipse:before {content: $solar-eclipse;}
.wi-hot:before {content: $hot;}
.wi-day-cloudy-high:before {content: $day-cloudy-high;}
.wi-day-light-wind:before {content: $day-light-wind;}