$night-clear: "\f02e";
$night-alt-cloudy: "\f086";
$night-alt-cloudy-gusts: "\f022";
$night-alt-cloudy-windy: "\f023";
$night-alt-hail: "\f024";
$night-alt-lightning: "\f025";
$night-alt-rain: "\f028";
$night-alt-rain-mix: "\f026";
$night-alt-rain-wind: "\f027";
$night-alt-showers: "\f029";
$night-alt-sleet: "\f0b4";
$night-alt-sleet-storm: "\f06a";
$night-alt-snow: "\f02a";
$night-alt-snow-thunderstorm: "\f06d";
$night-alt-snow-wind: "\f067";
$night-alt-sprinkle: "\f02b";
$night-alt-storm-showers: "\f02c";
$night-alt-thunderstorm: "\f02d";
$night-cloudy: "\f031";
$night-cloudy-gusts: "\f02f";
$night-cloudy-windy: "\f030";
$night-fog: "\f04a";
$night-hail: "\f032";
$night-lightning: "\f033";
$night-partly-cloudy: "\f083";
$night-rain: "\f036";
$night-rain-mix: "\f034";
$night-rain-wind: "\f035";
$night-showers: "\f037";
$night-sleet: "\f0b3";
$night-sleet-storm: "\f069";
$night-snow: "\f038";
$night-snow-thunderstorm: "\f06c";
$night-snow-wind: "\f066";
$night-sprinkle: "\f039";
$night-storm-showers: "\f03a";
$night-thunderstorm: "\f03b";
$lunar-eclipse: "\f070";
$stars: "\f077";
$storm-showers: "\f01d";
$thunderstorm: "\f01e";
$night-alt-cloudy-high: "\f07e";
$night-cloudy-high: "\f080";
$night-alt-partly-cloudy: "\f081";